import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import {Link, navigate} from 'gatsby'

import BackgroundIndex from '../images/index-background.png';
import BackgroundTorn from '../images/torn-bg.png';
import EventsIndex from '../images/events-index.png';
import IndexSmall from '../images/index-small.png';

import EventGlass from '../images/event-glass.png';
import TornEvents from '../images/torn-events.png';
// SVG
import Playdate from '../images/svg/playdate.svg';
import ArrowLink from '../images/svg/arrow-link.svg';
import BlackArrow from '../images/svg/black-arrow.svg';
import VectorEvent from '../images/svg/vector-upcoming-event.svg';

const Index = ({ data }) => {
    console.log("Index data : ", data);
    return (
        <main>
        <div className="bg-gray-900 lg:overflow-hidden relative" style={{  
            backgroundImage: "url(" + BackgroundIndex + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
            }}>
          <div className="mx-auto">
            <div className="md:grid md:grid-cols-2">
              <div className="mx-auto sm:max-w-2xl md:flex md:items-center md:px-0 md:text-left">
                <div className="md:py-24 md:pl-24">
                  <h1 className="text-comorant lg:text-7xl text-6xl tracking-tight text-white mb-6 pt-44 md:pt-0 md:px-0 px-14">
                    <span className="block"><span className="font-semibold">WHERE </span><span className='italic'>FANTASY</span></span>
                    <span className="block italic">BECOMES REALITY.</span>
                    <span className="block font-semibold">DON'T MISS OUT</span>
                    <span className="block font-semibold">THE NEXT PARTY !</span>
                  </h1>
                  <div className='block md:hidden w-full'>
                    <img className='w-full' src={IndexSmall} alt="Index Image" />
                  </div>
                </div>
              </div>
              <div className="lg:relative m-0 hidden md:block">
                <div className="mx-auto max-w-md sm:max-w-2xl lg:max-w-none lg:px-0">
                  {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                  <img className='float-right' src={EventsIndex} alt="Index Image" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* EVNTS TOP TEXT */}

        <div className="bg-light py-24 sm:py-32 lg:py-40">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="text-left text-comorant text-5xl">
            <span className='text-grey'>Our world-famous lifestyle events are the ultimate adult playgrounds. </span> We’ve been leading the swingers scene for nearly a decade, prioritizing the safety and pleasure of our members whilst.
            </div>
          </div>
        </div>

        {/* EVENT SECTION */}

        <div className="bg-light py-24 sm:py-32 lg:py-40 pt-60" style={{  
            backgroundImage: "url(" + TornEvents + ")",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}>
          <div className="mx-auto max-w-7xl px-6 lg:px-8 py-40">
            <h2 className='text-comorant text-left lg:text-8xl text-5xl uppercase'>
                <span className='block'>Upcoming</span> 
                <span className='block'>events</span>
            </h2>
            <div className="lg:block hidden">
                <img className='mx-auto' src={VectorEvent} alt="" />
              </div>
            <div className="flex justify-between pb-9 elem-event-page mt-20">

              <div className=''>
                <div className='text-comorant text-5xl mb-8'>Oct, 20th</div>
                <div className='text-grey text-lg	'>08:00pm - 11:00pm  </div>
              </div>

              <div className='text-3xl	'>
                Lifestyle party
              </div>

              <div className='relative'>
                <img class="event-page-picture hidden lg:block" src={EventGlass}/>
              </div>

              <div className=''>
                <img src={BlackArrow}/>
              </div>

            </div>

            <div className="flex justify-between pb-9 elem-event-page mt-20 opacity-30">

              <div className=''>
                <div className='text-comorant text-5xl mb-8'>Oct, 27th</div>
                <div className='text-grey text-lg	'>08:00pm - 11:00pm  </div>
              </div>

              <div className='text-3xl	'>
                Lifestyle party
              </div>

              <div className='relative'>

              </div>

              <div className=''>
                <img src={BlackArrow}/>
              </div>

            </div>

            <div className="flex justify-between pb-9 elem-event-page mt-20 opacity-30">

              <div className=''>
                <div className='text-comorant text-5xl mb-8'>Dec, 28th</div>
                <div className='text-grey text-lg	'>08:00pm - 11:00pm  </div>
              </div>

              <div className='text-3xl	'>
                New Year party
              </div>

              <div className='relative'>

              </div>

              <div className=''>
                <img src={BlackArrow}/>
              </div>

            </div>

            <div className="flex justify-between pb-9 elem-event-page mt-20 opacity-30">

              <div className=''>
                <div className='text-comorant text-5xl mb-8'>Apr, 8th</div>
                <div className='text-grey text-lg	'>08:00pm - 11:00pm  </div>
              </div>

              <div className='text-3xl	'>
                Lifestyle party
              </div>

              <div className='relative'>

              </div>

              <div className=''>
                <img src={BlackArrow}/>
              </div>

            </div>

          </div>
        </div>

      </main>
    )
}


export default Index